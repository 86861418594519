.box2 {
  background-image: linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%);
}

.box3 {
  background-image: linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%);
}

.box4 {
  background-image: linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%);
}